// import User from "../../services/user";
// import { hash } from "../../services/utils";
// import { language } from "@local/theme";
import type { ClusterId } from "../../types/cluster";
import type { RuleName } from "../../types/rule";
import type { RocIdDeviceCategory, RocIdDeviceType, QuickViewCategory } from "../../types/roc-table";
import type { ChannelInfoCategoryItem, ChannelInfoPairingDevice } from "../../types/message";
import type { RulesTab, HistoryTab, SmartMode, SmartModes } from "../../types/misc";
import type {
	BaseActionData, BaseCustomParam,
	SendType, SendEventName, ZwaveMode, HbDataLayerData,
} from "../../types/analytics";

const send = (type: SendType, data: object | boolean): void => {
	window.wts!.push(["send", type, data]);
};

const getBaseActionData = () => ({
	contentId: globalThis.location.href.substring(globalThis.location.protocol.length + 2).replaceAll(".", "_").replace("/#/", ".").replaceAll("/", "."), // window.hbDataLayer["app_platform"]
	customerId: window.hbDataLayer!.customerId!, // User.userInfo.extension_customerId
	email: window.hbDataLayer!.email!, // (await hash(User.userInfo.email)).toUpperCase()
	pageType: window.hbDataLayer!.pageType!,
} as const satisfies BaseActionData);

const getBaseCustomParam = () => ({
	1: window.hbDataLayer!.language!, // language
	3: window.hbDataLayer!.tld!, // globalThis.location.hostname.split(".").pop()
	24: window.hbDataLayer!.app_platform!,
} as const satisfies BaseCustomParam);

const sendPageUpdate = (withoutPlugins: boolean): void => {
	send("pageupdate", withoutPlugins);
};

const sendError = (errorMessage: string): void => {
	const data = {
		...getBaseActionData(),
		errorMessages: errorMessage,
	} as const;

	send("page", data);
};

const sendAction = (contentGroup: object, customParameter: object): void => {
	const data = {
		...getBaseActionData(),
		contentGroup: contentGroup,
		customParameter: { ...getBaseCustomParam(), ...customParameter },
	} as const;

	send("page", data);
};

const sendEvent = (eventName: SendEventName, customClickParameter?: object, customEcommerceParameter?: object): void => {
	const data = {
		linkId: eventName,
		customClickParameter: customClickParameter,
		customEcommerceParameter: customEcommerceParameter,
	} as const;

	send("click", data);
};

export const updateHbDataLayer = (hbDataLayerData: HbDataLayerData, withoutPlugins: boolean = false): void => {
	for (const key in hbDataLayerData) {
		if (hbDataLayerData.hasOwnProperty(key)) {
			window.hbDataLayer![key] = hbDataLayerData[key];
		}
	}

	sendPageUpdate(withoutPlugins);
};

/**
 * 3.2.2.1 Status
 * @deprecated Old quickview doesn't exist any more
 */
export const acGatewaySituation = (gatewaySituation: SmartMode, deviceGroupsCount: number, deviceGroupNames: Array<QuickViewCategory>, devicesPerGroup: Array<number>): void => {
	const contentGroup = {
		1: "status",
	} as const;
	const customParameter = {
		25: gatewaySituation,
		26: `${deviceGroupsCount}`,
		27: deviceGroupNames.join(";"),
		28: devicesPerGroup.join(";"),
	} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.1.1 Event: Gateway Situation ändern
 */
export const evGatewaySituation = (gatewaySituation: SmartMode): void => {
	const customClickParameter = {
		1: gatewaySituation,
	} as const;

	sendEvent("change_gateway_situation", customClickParameter);
};

/**
 * 3.2.2.2 Geräte
 */
export const acDevices = (deviceCategoriesCount: number, deviceCategoryNames: Array<RocIdDeviceCategory>, devicesPerCategory: Array<number>): void => {
	const contentGroup = {
		1: "devices",
	} as const;
	const customParameter = {
		29: `${deviceCategoriesCount}`,
		30: deviceCategoryNames.join(";"),
		31: devicesPerCategory.join(";"),
	} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.2.1 Geräte Details
 */
export const acDeviceDetails = (deviceName: RocIdDeviceType): void => {
	const contentGroup = {
		1: "devices",
		2: "device_details",
	} as const;
	const customParameter = {
		32: deviceName,
	} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.2.2 Geräte Messwerte
 */
export const acDeviceMeasuredValues = (clusterId: ClusterId, deviceName: RocIdDeviceType): void => {
	// NOTE: sync clusterIds with "device-graph"
	const clusterTypeNames = {
		"000C": "dew point",
		"0400": "illuminance",
		"0402": "temperature",
		"0403": "pressure",
		"0405": "humidity",
		"0702": "power_consumption",
		"FD01": "ph",
		"FD02": "oydo_reduction_potential",
		"FD03": "salt_concentration",
		"FD04": "total_dissolved_solids",
		"FF69": "temperature",
		"FF81": "carbon_monoxide",
		"FF84": "dt_lawn_mower",
		"FF94": "carbon_dioxide",
		"FF95": "volatile_organic_compound",
		"FF9F": "pm2_5",
	} as const satisfies Partial<Record<ClusterId, string>>;

	const contentGroup = {
		1: "devices",
		2: "device_details",
		3: clusterTypeNames[clusterId] ?? clusterId,
	} as const;
	const customParameter = {
		32: deviceName,
	} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.2.3 Event: Aktualisieren
 * TODO: unused
 */
export const evDeviceUpdate = (deviceName: string): void => { // TODO: check type
	const customClickParameter = {
		1: deviceName,
	} as const;

	sendEvent("update", customClickParameter);
};

/**
 * 3.2.2.3 Regeln
 */
export const acRules = (rulesCount: number): void => {
	const contentGroup = {
		1: "rules",
	} as const;
	const customParameter = {
		33: `${rulesCount}`,
	} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.3.1 Event: Regeln filtern
 */
export const evRulesTab = (rulesTab: RulesTab): void => {
	const customClickParameter = {
		1: rulesTab,
	} as const;

	sendEvent("filter_rules", customClickParameter);
};

/**
 * 3.2.2.3.2 Regel Details (Vorlagen)
 */
export const acRuleDetailsTemplate = (ruleName: RuleName): void => {
	const contentGroup = {
		1: "rules",
		2: "rule_details",
	} as const;
	const customParameter = {
		34: ruleName,
	} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.3.3 Regel Details (Zeitschaltplan)
 */
export const acRuleDetailsTimeSwitchingDiagram = (deviceName: RocIdDeviceType, ruleName: RuleName): void => {
	const contentGroup = {
		1: "rules",
		2: "rule_details",
	} as const;
	const customParameter = {
		32: deviceName,
		34: ruleName,
	} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.3.4 Event: Gateway Situation ändern
 */
export const evGatewaySituationChange = (gatewaySituations: SmartModes): void => {
	const customClickParameter = {
		1: gatewaySituations.join(";"),
	} as const;

	sendEvent("select_gateway_situation", customClickParameter);
};

/**
 * 3.2.2.4 Historie
 */
export const acHistory = (): void => {
	const contentGroup = {
		1: "history",
	} as const;
	const customParameter = {};

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.4.1 Event: Historie eingrenzen
 */
export const evHistoryTab = (historyTab: HistoryTab): void => {
	const customClickParameter = {
		1: historyTab,
	} as const;

	sendEvent("select_history", customClickParameter);
};

/**
 * 3.2.2.4.2 Event: Historie Filtern
 */
export const evHistoryFilter = (historyFilterCount: number): void => {
	const customClickParameter = {
		1: `${historyFilterCount} devices_selected`,
	} as const;

	sendEvent("filter_history_by_device", customClickParameter);
};

/**
 * 3.2.2.5.1 Account
 */
export const acAccount = (): void => {
	const contentGroup = {
		1: "settings",
		2: "account",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.5.2 Event: Account löschen
 */
export const evAccountDelete = (): void => {
	sendEvent("delete_account");
};

/**
 * 3.2.2.5.4 Event: Z-Wave aktivieren
 */
export const evZWaveMode = (zwaveMode: ZwaveMode): void => {
	const customClickParameter = {
		1: zwaveMode,
	} as const;

	sendEvent("z-wave", customClickParameter);
};

/**
 * 3.2.2.6 Support
 */
export const acSupport = (): void => {
	const contentGroup = {
		1: "support",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.7 About
 */
export const acAbout = (): void => {
	const contentGroup = {
		1: "about",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.7.1 AGB
 */
export const acAboutUserTerms = (): void => {
	const contentGroup = {
		1: "about",
		2: "user_terms",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.7.2 Impressum
 */
export const acAboutImprint = (): void => {
	const contentGroup = {
		1: "about",
		2: "imprint",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.8.1 Gateway
 */
export const acAddGateway = (): void => {
	const contentGroup = {
		1: "add",
		2: "gateway",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.8.2 Event: Gateway hinzufügen
 */
export const evAddGateway = (): void => {
	const customClickParameter = {
		1: "gateway",
	} as const;
	const customEcommerceParameter = {
		50: "add_gateway",
	} as const;

	sendEvent("add", customClickParameter, customEcommerceParameter);
};

/**
 * 3.2.2.8.3 Gerät
 */
export const acAddDevice = (): void => {
	const contentGroup = {
		1: "add",
		2: "device",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.8.4 Event: Gerät hinzufügen
 */
export const evAddDevice = (deviceCategoryName: NonNullable<ChannelInfoCategoryItem["name"]>, deviceName: NonNullable<ChannelInfoPairingDevice["name"]>): void => {
	const customClickParameter = {
		1: "device",
		2: deviceCategoryName,
		3: deviceName,
	} as const;
	const customEcommerceParameter = {
		51: "add_device",
	} as const;

	sendEvent("add", customClickParameter, customEcommerceParameter);
};

/**
 * 3.2.2.8.5 Gruppe
 */
export const acAddGroup = (): void => {
	const contentGroup = {
		1: "add",
		2: "group",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.8.6 Event: Gruppe erstellen
 */
export const evAddGroup = (devicesCount: number): void => {
	const customClickParameter = {
		1: "group",
		4: `${devicesCount}`,
	} as const;
	const customEcommerceParameter = {
		52: "add_group",
	} as const;

	sendEvent("add", customClickParameter, customEcommerceParameter);
};

/**
 * 3.2.2.8.7 Regel
 */
export const acAddRule = (): void => {
	const contentGroup = {
		1: "add",
		2: "rule",
	} as const;
	const customParameter = {} as const;

	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.8.8 Event: Regel hinzufügen
 */
export const evAddRule = (gatewaySituations: SmartModes, ruleName: RuleName): void => {
	const customClickParameter = {
		1: "rule",
		5: gatewaySituations.join(";"),
		6: ruleName,
	} as const;
	const customEcommerceParameter = {
		53: "add_rule",
	} as const;

	sendEvent("add", customClickParameter, customEcommerceParameter);
};

export const acExplorePage = (): void => {
	const contentGroup = {
		1: "explore",
	} as const;
	const customParameter = {} as const;
	sendAction(contentGroup, customParameter);
};

/**
 * 3.2.2.8.9 Fehlermeldung
 */
export const acError = (errorMessage: string): void => {
	sendError(errorMessage);
};

/**
 * 3.2 Event: Explore Carousel Item View
 */
export const evExploreCarouselView = (text: string, position: number): void => {
	const customClickParameter = {
		1: text,
		9: `${position}`,
	} as const;

	sendEvent("karussel_view", customClickParameter);
};

/**
 * 3.2 Event: Explore Carousel Item Click
 */
export const evExploreCarouselClick = (text: string, position: number): void => {
	const customClickParameter = {
		1: text,
		9: `${position}`,
	} as const;

	sendEvent("karussel_click", customClickParameter);
};

/**
 * 3.3 Event: Brands interaction
 */
export const evExploreBrandsInteraction = (text: string, interactionType: string, position: number): void => {
	const customClickParameter = {
		7: text,
		8: interactionType,
		9: `${position}`,
	} as const;

	sendEvent("webtrekk_ignore", customClickParameter);
};

/**
 * 3.4 Event: Explore Click
 */
export const evExploreClick = (text: string): void => {
	const customClickParameter = {
		1: text,
	} as const;

	sendEvent("click", customClickParameter);
};

/**
 * 3.4 Event: Explore Country Change
 */
export const evExploreChangeCountry = (countryName: string): void => {
	const customClickParameter = {
		1: countryName,
	} as const;

	sendEvent("change_country", customClickParameter);
};
